// import ErrorHandler from "./errorHandler";
import Config from "../../config/Config.ts";
import { setupInterceptorsTo } from "./interceptors.ts";
import axios from "axios";
import { check } from "src/config/global";
// import {
//     createArrayEntityFromObject,
//     createQueryUrl,
//     getEntity,
// } from "../../config/global";
setupInterceptorsTo(axios);

const baseUrl = Config.BaseUrl;

export const checkCompany = (initial) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/admin/company/initial/${initial}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const login = (data, otp = null) => {
  let headers = {
    accept: "application/json",
  };
  if (otp) headers["OTP"] = otp;
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}authentication/admin/login`, data, {
        headers,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const isFirstLogin = (data) => {
  return new Promise((resolve, reject) => {
    let postData = {
      password: data.password,
    };
    axios
      .post(
        `${baseUrl}user/account/admin/change_password_first_login`,
        postData,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage
              .getItem("token_sn_admin_redux")
              .replaceAll('"', "")}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cekToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}authentication/admin/token`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendOtp = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}authentication/guest/otp/request`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}authentication/admin/logout`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const otpRequest = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}authentication/admin/otp/request`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const otpRequestCustom = (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}${url}`, data, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const otpConfirmCustom = (url, data, method = "post") => {
  return new Promise((resolve, reject) => {
    axios[method](`${baseUrl}${url}`, data, {
      headers: {
        Authorization: `Bearer ${check()}`,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changeCompany = (companyId) => {
  let url = `${baseUrl}authentication/admin/change-company`;
  if (companyId) {
    url = `${baseUrl}authentication/admin/change-company?idCompany=${companyId}`;
  } else {
    url;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const timeOtp = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}authentication/admin/otp/config/${type}`, {
        headers: {
          Authorization: `Bearer ${check()}`,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkPrefixPath = (prefix) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}user/info/company/prefix/get/${prefix}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const requestOtpLogin = (data) => {
  let url = `${baseUrl}authentication/admin/login/request/otp`;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMemberLastCheckToken = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${baseUrl}authentication/admin/data/member-last-check-token`,
        data,
        {
          headers: {
            Authorization: `Bearer ${check()}`,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import Icons from "src/config/icon_menu";

const _navSubCompany = [
  // {
  //   component: CNavItem,
  //   name: "Dashboard",
  //   to: "/dashboard",
  //   icon: Icons.sideBar.dashboard,
  //   permission: "all",
  // },

  {
    component: CNavItem,
    name: "Info Perusahaan",
    to: "/main/company-info",
    icon: Icons.sideBar.infoPerusahaan,
    permission: "all",
  },

  {
    component: CNavGroup,
    name: "data_user",
    icon: Icons.sideBar.dataUser,
    permission: ["data_user"],
    items: [
      {
        component: CNavItem,
        name: "Data Member",
        permission: "data_user",
        to: "/user/member",
      },
      {
        component: CNavItem,
        name: "Saldo Member",
        permission: "saldo_dan_pembayaran",
        to: "/balance-payment/balance/member-balance",
      },
    ],
  },

  {
    component: CNavItem,
    name: "Data Notifikasi",
    icon: Icons.sideBar.dataNotifikasi,
    to: "/notification-management/record-notification",
    permission: "data_notifikasi",
  },
  {
    component: CNavItem,
    name: "info_dan_berita",
    to: "/news-info",
    icon: Icons.sideBar.infoBerita,
    permission: "info_dan_berita",
  },
  {
    component: CNavGroup,
    name: "invoice",
    icon: Icons.sideBar.tagihan,
    permission: ["invoice"],
    items: [
      {
        component: CNavItem,
        name: "Master Invoice",
        to: "/invoice/master-invoice",
        permission: "invoice",
      },
      {
        component: CNavItem,
        name: "Invoice Tunggal",
        to: "/invoice/invoice-tunggal",
        permission: "invoice",
      },
      {
        component: CNavGroup,
        name: "Data Invoice",
        permission: ["invoice"],
        items: [
          {
            component: CNavItem,
            name: "Invoice Admin",
            permission: "invoice",
            to: "/invoice/invoice-admin",
          },
          {
            component: CNavItem,
            name: "Invoice Member",
            permission: "invoice",
            to: "/invoice/invoice-member",
          },
        ],
      },
      {
        component: CNavItem,
        name: "Data Transaksi Invoice",
        to: "/invoice/data-transaksi-invoice",
        permission: "invoice",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "manajemen_accessibility",
    icon: Icons.sideBar.manajemenAkses,
    permission: ["manajemen_accessibility"],
    items: [
      {
        component: CNavGroup,
        name: "Absensi",
        permission: ["manajemen_accessibility"],
        items: [
          {
            component: CNavItem,
            name: "Kelola Aktivitas Absensi",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/attendance/activity",
          },
          {
            component: CNavItem,
            name: "Riwayat Absensi Pengguna",
            permission: "manajemen_accessibility",
            to: "/accessibility-management/attendance/history",
          },
        ],
      },
    ],
  },
];

export default _navSubCompany;

import React from "react";
const chargeMarketplace = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/charge/charge")
);
const chargeChasier = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/chasier/charge/charge")
);
const productMarketplace = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/product/product")
);
const ordersMarketplace = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/orders/orders")
);
const ordersMarketplaceDetail = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/orders/edit")
);
const categoryMarketplace = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/category/category")
);
const formOrderMarketplace = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/config/formOrder")
);
const settingFormMarketplace = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/config/settingForm")
);
const autoConfirmMarketplace = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/config/autoConfirm")
);
const menuNamingMarketplace = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/config/menuNaming")
);
const detailProductMarketplace = React.lazy(() =>
  import(
    "../views/pages/marketplaceAndChasier/marketplace/product/detailProduct"
  )
);
const categoryMarketplaceEdit = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/category/edit")
);
const categoryMarketplaceNew = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/marketplace/category/new")
);
const productChasier = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/chasier/product/product")
);
const detailProductChasier = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/chasier/product/detailProduct")
);
const categoryCashier = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/chasier/category/category")
);
const categoryCashierEdit = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/chasier/category/edit")
);
const categoryCashierNew = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/chasier/category/new")
);
const ordersCashier = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/chasier/orders/orders")
);
const ordersCashierDetail = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/chasier/orders/edit")
);
const merchantActivation = React.lazy(() =>
  import(
    "../views/pages/marketplaceAndChasier/marketplace/config/merchantActivation"
  )
);
const propertiesMenuCommerce = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/properties/properties")
);
const merchantPaymentConfigFnb = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/fnb/paymentConfig/paymentConfig")
);
const orderDataFnb = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/fnb/orderData/orderFnb")
);
const detailOrderDataFnb = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/fnb/orderData/detailOrderFnb")
);

const orderReceiver = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/orderReceiver/orderReceiver")
);
const newOrderReceiver = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/orderReceiver/new")
);
const editOrderReceiver = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/orderReceiver/edit")
);
const provisionCustomizationMarketplace = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/selectConditionCustom")
);
const dataVenue = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/venue/venue")
);
const newDataVenue = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/venue/new")
);
const editDataVenue = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/venue/edit")
);
const dataVenueCategory = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/product/category/category")
);
const dataVenueProduct = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/product/product")
);
const newDataVenueProduct = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/product/new")
);
const editDataVenueProduct = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/product/edit")
);
const dataVenueSchedule = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/schedule/schedule")
);
const newDataVenueSchedule = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/schedule/new")
);
const editDataVenueSchedule = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/schedule/edit")
);
const dataRentVenueHistory = React.lazy(() =>
  import("../views/pages/marketplaceAndChasier/rent/history/history")
);

const marketplaceRoutes = [
  {
    path: "/marketplace-cashier/marketplace/order-fees",
    element: chargeMarketplace,
  },
  {
    path: "/marketplace-cashier/cashier/transaction-fees",
    element: chargeChasier,
  },
  {
    path: "/marketplace-cashier/marketplace/product",
    element: productMarketplace,
  },
  {
    path: "/marketplace-cashier/marketplace/transactions-orders",
    element: ordersMarketplace,
  },
  {
    path: "/marketplace-cashier/marketplace/transactions-orders/:id",
    element: ordersMarketplaceDetail,
  },
  {
    path: "/marketplace-cashier/marketplace/category",
    element: categoryMarketplace,
  },
  {
    path: "/marketplace-cashier/marketplace/order-form",
    element: formOrderMarketplace,
  },
  {
    path: "/marketplace-cashier/marketplace/order-form/setting",
    element: settingFormMarketplace,
  },
  {
    path: "/marketplace-cashier/marketplace/auto-confirm",
    element: autoConfirmMarketplace,
  },
  {
    path: "/marketplace-cashier/marketplace/menu-naming",
    element: menuNamingMarketplace,
  },
  {
    path: "/marketplace-cashier/marketplace/product/detail/:id",
    element: detailProductMarketplace,
  },
  {
    path: "/marketplace-cashier/marketplace/category/new",
    element: categoryMarketplaceNew,
  },
  {
    path: "/marketplace-cashier/marketplace/category/:id",
    element: categoryMarketplaceEdit,
  },
  {
    path: "/marketplace-cashier/cashier/product",
    element: productChasier,
  },
  {
    path: "/marketplace-cashier/cashier/product/detail/:id",
    element: detailProductChasier,
  },
  {
    path: "/marketplace-cashier/cashier/category",
    element: categoryCashier,
  },
  {
    path: "/marketplace-cashier/cashier/category/new",
    element: categoryCashierNew,
  },
  {
    path: "/marketplace-cashier/cashier/category/:id",
    element: categoryCashierEdit,
  },
  {
    path: "/marketplace-cashier/cashier/transactions",
    element: ordersCashier,
  },
  {
    path: "/marketplace-cashier/cashier/transactions/:id",
    element: ordersCashierDetail,
  },
  {
    path: "/marketplace-cashier/marketplace/merchant-activation",
    element: merchantActivation,
  },
  {
    path: "/marketplace-cashier/properties-menu",
    element: propertiesMenuCommerce,
  },
  {
    path: "/marketplace-cashier/fnb/merchant-payment-config",
    element: merchantPaymentConfigFnb,
  },
  {
    path: "/marketplace-cashier/fnb/order-data",
    element: orderDataFnb,
  },
  {
    path: "/marketplace-cashier/fnb/order-data/:id",
    element: detailOrderDataFnb,
  },

  {
    path: "/marketplace-cashier/cashier/order-receiver",
    element: orderReceiver,
  },
  {
    path: "/marketplace-cashier/cashier/order-receiver/new",
    element: newOrderReceiver,
  },
  {
    path: "/marketplace-cashier/cashier/order-receiver/edit/:id",
    element: editOrderReceiver,
  },
  {
    path: "/marketplace-cashier/marketplace/provisions-customization",
    element: provisionCustomizationMarketplace,
  },
  {
    path: "/marketplace-cashier/rent/venue",
    element: dataVenue,
  },
  {
    path: "/marketplace-cashier/rent/venue/new",
    element: newDataVenue,
  },
  {
    path: "/marketplace-cashier/rent/venue/edit/:id",
    element: editDataVenue,
  },
  {
    path: "/marketplace-cashier/rent/product&category",
    element: dataVenueCategory,
  },
  {
    path: "/marketplace-cashier/rent/product&category/product",
    element: dataVenueProduct,
  },
  {
    path: "/marketplace-cashier/rent/product&category/product/new",
    element: newDataVenueProduct,
  },
  {
    path: "/marketplace-cashier/rent/product&category/product/edit/:id",
    element: editDataVenueProduct,
  },
  {
    path: "/marketplace-cashier/rent/schedule",
    element: dataVenueSchedule,
  },
  {
    path: "/marketplace-cashier/rent/schedule/new",
    element: newDataVenueSchedule,
  },
  {
    path: "/marketplace-cashier/rent/schedule/edit/:id",
    element: editDataVenueSchedule,
  },
  {
    path: "/marketplace-cashier/rent/history",
    element: dataRentVenueHistory,
  },
];

export default marketplaceRoutes;
